<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import helpers from '../../../helpers'
import Timeline from "@/components/dashboard/broadcasts/timeline"

import {
  analyticsMethods
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Broadcasts Analytics",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    DatePicker,
    Timeline
  },
  data() {
    return {
      title: this.$t('kpi.title'),
      items: [],
      
      showModal: false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      query: '',
      user: JSON.parse(localStorage.getItem("auth.currentUser")).user,
      dateFilter: null,
      disabledBefore: new Date().setDate(new Date().getDate() - 90),
      shortcuts: [
        {
          text: this.$t('kpi.filter_date_today'),
          onClick() {
            const start = new Date();
            const end = new Date();
            
            end.setTime(end.getTime() + 0 * 24 * 3600 * 1000);
            const date = [start, end];
            
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_yesterday'),
          onClick() {
            
            const start = new Date();
            const end = new Date();
            
            start.setTime(start.getTime() -1 * 24 * 3600 * 1000);
            const date = [start, end];
            
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_week'),
          onClick() {
            
            var curr = new Date; // get current date
            var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6

            var firstday = new Date(curr.setDate(first));
            var lastday = new Date(curr.setDate(last));
            
            const date = [firstday, lastday];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_month'),
          onClick() {
            var today = new Date; // get current date
            const firstday = new Date(today.getFullYear(), today.getMonth(), 1);
            //const lastday = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const date = [firstday, today];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_week'),
          onClick() {
            const today = new Date();
            const lastWeek = new Date(today);
            lastWeek.setDate(today.getDate() - 7); // Resta 7 días para obtener la semana pasada

            // Obtén el primer día de la semana pasada (domingo)
            const firstDay = new Date(lastWeek);
            firstDay.setDate(lastWeek.getDate() - lastWeek.getDay());

            // Obtén el último día de la semana pasada (sábado)
            const lastDay = new Date(lastWeek);
            lastDay.setDate(lastWeek.getDate() - lastWeek.getDay() + 6);

            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_month'),
          onClick() {
            const today = new Date();
  
            // Calculate the first day of the last month
            const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  
            // Calculate the last day of the last month
            const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_7_days'),
          onClick() {
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 6); // First day is 7 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_30_days'),
          onClick() {
            
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 29); // First day is 30 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
      ],

      isLocal: window.config.local ? true : false,
      broadcastsAnalytics: [],
      filters:[],

      executions: 0,
      error: 0,
      success: 0,
      opened: 0,
      views: 0,
      conversions: 0,
      conversion_rate: 0,
      revenue: 0,
      purchases: 0,
      open_rate: 0,
      click_rate: 0,
      deliver_rate: 0,

      previousAnalytics: [],
      previous_executions: 0,
      previous_delivery_rate: 0,
      previous_open_rate: 0,
      previous_click_rate: 0,
      previous_revenue: 0,
      previous_conversion_rate: 0,
      periodLength: 0
    };
  },
  mounted() {
    const today = new Date();
    const lastDay = new Date(today); 
    const firstDay = new Date(today);
    firstDay.setDate(today.getDate() - 29); 
    
    this.dateFilter = [firstDay, lastDay]; 

  },
  methods:{
    ...analyticsMethods,
    ...helpers,

    formatDate(d) {
      
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;
      
      return [year, month, day].join('-');
    },
    getCustomFilterQuery(){
      let q = '';
      if(this.filters.length>0){
        this.filters.forEach((f)=>{
          if(f.key!='compare'){
            q+= `&key=${f.key}&value=${f.value}`
          }
        });
      }
      return q;
    },

    applyFilter(query){
      if(this.filters.length > 0){
        query+= this.getCustomFilterQuery();
      }
      this.query = query;

      const loader = this.$loading.show();

      this.getBroadcastsReports(query).then((res)=>{
        this.broadcastsAnalytics = res.data;
        // eslint-disable-next-line no-console
        console.log('analytics', this.broadcastsAnalytics)

        this.executions = this.getSummaryByKey('executions',this.broadcastsAnalytics);
        this.success = this.getSummaryByKey('success',this.broadcastsAnalytics);
        this.error = this.getSummaryByKey('error',this.broadcastsAnalytics);
        
        this.revenue = this.broadcastsAnalytics > 0 ? this.getSummaryByKey('revenue', this.broadcastsAnalytics): 0;
        this.opened = this.getSummaryByKey('opened',this.broadcastsAnalytics);
        this.views = this.getSummaryByKey('views',this.broadcastsAnalytics) || 0;

        this.conversions = this.getSummaryByKey('converted',this.broadcastsAnalytics);
        this.conversion_rate = this.success == '-' ? `-` : this.success == 0 ? 0 : this.parsePercentage(this.conversions/this.success * 100)
        this.open_rate = this.success == '-' ? `-` : this.success == 0 ? 0 : this.parsePercentage(this.opened/this.success * 100)
        this.click_rate = this.success == '-' ? `-` : this.success == 0 ? 0 : this.parsePercentage(this.views/this.success * 100)
        this.deliver_rate = this.executions == '-' ? `-` : this.executions == 0 ? 0 : this.parsePercentage(this.success/this.executions * 100)

        if(this.filters.filter(f=>f.key=='compare').length>0){
          this.getPreviousIndicators();
        }
      }).finally(()=>{
        loader.hide();
      });
    },
    onRemoveFilter(filter){
      this.filters = this.filters.filter(f=> f.key != filter.key);
      
      if(filter.key== 'compare'){
        this.previousAnalytics = [];
      }

      const nextDay = new Date(this.dateFilter[1]);
      nextDay.setDate(this.dateFilter[1].getDate() + 1)

      let query = `project=${localStorage.getItem('current_project')}`
      query+= `&dateFrom=${this.formatDate(this.dateFilter[0])}&dateTo=${this.formatDate(nextDay)}`;

      this.applyFilter(query)
      
      },
      onComparePreviousPeriod(){
        if(this.filters.filter(f=>f.key=='compare').length == 0){
            this.filters.push({
              badge: `${this.$t('common.compare_badge')}`,
              key: 'compare'
            })
            this.applyFilter(this.query);
        }
        
        this.$refs.actionsDropdown.hide();
      },

      getPreviousIndicators (){

        const dates = this.getPreviousPeriod(this.dateFilter[0],this.dateFilter[1]);
        const nextDay = new Date(dates.previousDateTo);
        nextDay.setDate(dates.previousDateTo.getDate() + 1)

        this.previousDates = dates;

        let queryPrevious = `project=${localStorage.getItem('current_project')}`
        queryPrevious+= `&dateFrom=${this.formatDate(dates.previousDateFrom)}&dateTo=${this.formatDate(nextDay)}`;  

        queryPrevious+= this.getCustomFilterQuery();
        
        this.getBroadcastsReports(queryPrevious).then((res)=>{

          this.previousAnalytics = res.data;

          const prev_executions = this.getSummaryByKey('executions',this.previousAnalytics) || 0;
          this.previous_executions = this.calculatePercentageChange(this.executions, prev_executions) ;

          const prev_success = this.getSummaryByKey('success',this.previousAnalytics);
          const prev_revenue = this.getSummaryByKey('revenue',this.previousAnalytics);
          const prev_opened = this.getSummaryByKey('opened',this.previousAnalytics);
          const prev_views = this.getSummaryByKey('views',this.previousAnalytics);
          
          const prev_open_rate =  prev_success == '-' ? `-` : prev_success == 0 ? 0 : this.parsePercentage(prev_opened/prev_success * 100)
          this.previous_open_rate = this.calculatePercentageChange(this.open_rate, prev_open_rate) ;

          const prev_click_rate = prev_success == '-' ? `-` : prev_success == 0 ? 0 : this.parsePercentage(prev_views/prev_success * 100)
          this.previous_click_rate = this.calculatePercentageChange(this.click_rate, prev_click_rate) ;

          const prev_delivery_rate = prev_executions == '-' ? `-` : prev_executions == 0 ? 0 : this.parsePercentage(prev_success/prev_executions * 100)
          this.previous_delivery_rate = this.calculatePercentageChange(this.deliver_rate, prev_delivery_rate) ;

          const prev_conversions = this.getSummaryByKey('converted',this.previousAnalytics);
          const prev_conversion_rate = prev_success == '-' ? `-` : prev_success == 0 ? 0 : this.parsePercentage(prev_conversions/prev_success * 100)
          this.previous_conversion_rate = this.calculatePercentageChange(this.conversion_rate, prev_conversion_rate) ;

          this.previous_revenue = this.calculatePercentageChange(this.revenue, prev_revenue) ;

        });
    },
    onFilterClicked(){

    }
  },
  watch: {
    dateFilter: {
      immediate:true,
      handler(newVal){
        if(newVal){
          const nextDay = new Date(newVal[1]);
          nextDay.setDate(newVal[1].getDate() + 1)

          let query = `project=${localStorage.getItem('current_project')}`
          query+= `&dateFrom=${this.formatDate(newVal[0])}&dateTo=${this.formatDate(nextDay)}`;  

          const timeDifference = newVal[1].getTime() - newVal[0].getTime();
          this.periodLength =  Math.trunc(timeDifference / (1000 * 3600 * 24) + 1);

          this.applyFilter(query)
        }
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row mb-4">
      <div class="col-lg-12 mb-3">
        <div class="d-flex align-items-center">
          <div class="ms-3 flex-grow-1 mb-0">
            <h5 class="mb-0 card-title" style="font-size: 17px;">{{$t('broadcast_analyze.title')}}</h5>
            <p class="text-muted mb-0">{{ $t('broadcast_analyze.subtitle') }}</p>
            <a class="small text-primary mb-0" href="https://academy.gopersonal.ai/guia-de-usuario/analytics/broadcasts" target="_blank">{{ $t('common.visit_academy') }} <i class="bx bx-link-external"></i></a>
          </div>
          <div class="text-end w-50">
            <div class="row align-items-center justify-content-end">
              <div class="col-auto ps-0 w-50">
                <div class="flex">
                    <date-picker v-model="dateFilter" :shortcuts="shortcuts" placeholder="Selecioná la fecha" range
                      :format="'DD.MM.YYYY'" :clearable="false"
                      :disabled-date="(date) => date >= new Date() || date < new Date().setDate((new Date()).getDate() - 90)">
                    </date-picker>
                </div>
              </div>
              <div class="col-auto ms-0 ps-0">
                <b-dropdown ref="actionsDropdown" ngbDropdown menu-class="dropdown-menu-sm dropdown-menu-end pb-0" right variant="primary">
                    <template v-slot:button-content class="ms-2">
                      <i class="mdi mdi-apps me-1 text-white"></i>
                      <span class="d-none d-sm-inline-block" ngbDropdownToggle>
                        {{$t('common.actions')}}
                        <i class="mdi mdi-chevron-down"></i>
                      </span>
                    </template>
                    
                    <a class="dropdown-item" href="javascript: void(0);" v-on:click="onFilterClicked" v-if="false">
                      <i class="mdi mdi-filter-variant"></i>
                      {{ $t('common.filter') }}
                    </a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="javascript: void(0);" v-on:click="onComparePreviousPeriod">
                      <i class="mdi mdi-compare"></i>
                      {{ $t('common.compare') }}
                    </a>
                    <div class="dropdown-divider mb-0"></div>
                  </b-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="badge bg-primary m-1" v-for="f in filters" :key="f.key">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{ f.key!= 'compare' ? f.badge : `${f.badge} (${periodLength} ${periodLength>1 ? $t('common.days_badge') : $t('common.day_badge')})` }}</span>
              <i class="fa fa-times interact " v-on:click="onRemoveFilter(f)"></i>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 text-center">
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{ $t('broadcast_analyze.sent') }}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('broadcast_analyze.sent_tooltip')"/>
                  </p>
                  <h3 class="mb-0" v-b-popover.hover.bottom="executions">{{ parseCountReduced(executions)}}</h3>
                  <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_executions === 0,
                        'badge-soft-danger': previous_executions < 0,
                        'badge-soft-success': previous_executions > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_executions > 0 ? '+'  : '' } ${ previous_executions}%` }}</span>
                </div>
              </div>
              <div class="col-sm-2 text-center" >
                <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                      {{ $t('broadcast_analyze.delivery_rate') }}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('broadcast_analyze.delivery_rate_tooltip')"/>
                    </p>
                    <h3 class="mb-0">{{deliver_rate}}%</h3>
                    <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_delivery_rate === 0,
                        'badge-soft-danger': previous_delivery_rate < 0,
                        'badge-soft-success': previous_delivery_rate > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_delivery_rate > 0 ? '+'  : '' } ${ previous_delivery_rate}%` }}</span>
                  </div>
                </div>
              <div class="col-sm-2 text-center">
                <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                      {{ $t('broadcast_analyze.open_rate') }}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('broadcast_analyze.open_rate_tooltip')"/>
                    </p>
                    <h3 class="mb-0">{{open_rate}}%</h3>
                    <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_open_rate === 0,
                        'badge-soft-danger': previous_open_rate < 0,
                        'badge-soft-success': previous_open_rate > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_open_rate > 0 ? '+'  : '' } ${ previous_open_rate}%` }}</span>
                  </div>
              </div>
              <div class="col-sm-2 text-center" style="border-right-style: solid; border-right-color: lightgray;">
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{ $t('broadcast_analyze.click_rate') }}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('broadcast_analyze.click_rate_tooltip')"/>
                  </p>
                  <h3 class="mb-0">{{ click_rate }}%</h3>
                  <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_click_rate === 0,
                        'badge-soft-danger': previous_click_rate < 0,
                        'badge-soft-success': previous_click_rate > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_click_rate > 0 ? '+'  : '' } ${ previous_click_rate}%` }}</span>
                </div>
            </div>
            <div class="col-sm-2 text-center" >
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{ $t('broadcast_analyze.conversion_rate') }}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('broadcast_analyze.conversion_rate_tooltip')"/>
                  </p>
                  <h3 class="mb-0">{{conversion_rate}}%</h3>
                  <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_conversion_rate === 0,
                        'badge-soft-danger': previous_conversion_rate < 0,
                        'badge-soft-success': previous_conversion_rate > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_conversion_rate > 0 ? '+'  : '' } ${ previous_conversion_rate}%` }}</span>
                </div>
            </div>
            <div class="col-sm-2 text-center" >
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{ $t('broadcast_analyze.revenue') }}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.left="$t('broadcast_analyze.revenue_tooltip')"/>
                  </p>
                  <h3 class="mb-0" v-b-popover.hover.bottom="`$${parseMoneyWithLocale(revenue)}`">{{ `$${parseMoneyReducedWithLocale(revenue)}` }}</h3>
                  <span class="badge badge-soft-success font-size-12" :class="{
                        'badge-soft-warning': previous_revenue === 0,
                        'badge-soft-danger': previous_revenue < 0,
                        'badge-soft-success': previous_revenue > 0,
                        // eslint-disable-next-line vue/no-parsing-error
                        }" v-if="filters.filter(f=>f.key=='compare').length > 0"> {{ `${previous_revenue > 0 ? '+'  : '' } ${ previous_revenue}%` }}</span>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
        <div class="card">
          <Timeline :query="query" :trendings="broadcastsAnalytics" :compare="filters.filter(f=>f.key=='compare').length > 0" :oldTrendings="previousAnalytics" :periodLength="periodLength"/>
        </div>
      </div>
    </div>
</Layout>
</template>

<style>
.mx-btn-shortcut{
  font-size: 13px !important;
  font-family: var(--bs-body-font-family);
  padding: 0 4px;
  color: var(--bs-primary)
}

.mx-datepicker-sidebar + .mx-datepicker-content {
    margin-left: 150px !important;
    border-left: 1px solid #e8e8e8;
}

.mx-datepicker-sidebar {
  width: 120px;
}

.cell.active{
  background-color: var(--bs-primary) !important;
}

.dropdown-toggle {
  padding-top: 5px !important;
}
</style>
