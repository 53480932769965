<script>
import Multiselect from "vue-multiselect";
import helpers from "../../../helpers";

import {
  analyticsMethods,
} from "@/state/helpers";

export default {
  data() {
    return {
      
        series: [],
        chartoptions: {
          chart: {
            height: 350,
            type: "area",
            sparkline: {
                enabled: false,
              },
            toolbar: {
              show: false,
            },
          },
          colors: ["#5e40bf", "#f1b44c"],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
            width: 2,
            dashArray: [0, 5]
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.45,
              opacityTo: 0.05,
              stops: [20, 100, 100, 100],
            },
          },
          yaxis:{
            labels: {
              show:true
            }
          }          
        },
      
      selection: "all",
      values: [
        {id: 'sent', label: this.$t('broadcast_analyze.sent')},
        {id: 'success', label: this.$t('broadcast_analyze.success')},
        {id: 'opened', label: this.$t('broadcast_analyze.opened')},
        {id: 'views', label: this.$t('broadcast_analyze.views')},
        {id: 'converted', label: this.$t('broadcast_analyze.converted')},
        {id: 'revenue', label: this.$t('broadcast_analyze.revenue')},
      ],
      auxValue: {id: 'sent', label: this.$t('broadcast_analyze.sent')},
      groupButtonSelected: 'day',

      weeklyTendings: [],
      monthlyTendings:[],

      monthlyPreviousTendings: [],
      weeklyPreviousTendings: [],
    };
  },
  components:{Multiselect},
  props: {
    query : String,
    trendings: Array,
    compare: Boolean,
    oldTrendings: Array,
    periodLength: Number
  },
  mounted(){
    const self = this;

    this.chartoptions.xaxis = {
        labels: {
          formatter: function (valor) {
            return self.parseDateWithLocale(new Date(valor),self.getDateConfigByOptions());
          }
        }
      }
      this.chartoptions.yaxis = {
        labels: {
          formatter: function (valor) {
            if(self.auxValue.id =='revenue'){
              return `$${self.parseMoneyWithLocale(valor)}`;
            }
            else if(self.auxValue.id =='conversion_rate'){
              return `${self.parsePercentage(valor)}%`;
            }else{
              return valor;
            }
          }
        }
      }
      this.$refs.TrendsChart.updateOptions(this.chartoptions);
  },
  computed: {
    getSubtitleText(){
      switch(this.auxValue.id){
        case 'sent':
          return this.$t('broadcast_analyze.sent_subtitle')
        case 'success':
          return this.$t('broadcast_analyze.success_subtitle')
        case 'opened':
          return this.$t('broadcast_analyze.opened_subtitle')
        case 'views':
          return this.$t('broadcast_analyze.views_subtitle')
        case 'revenue':
          return this.$t('broadcast_analyze.revenue_subtitle')
        case 'converted':
          return this.$t('broadcast_analyze.converted_subtitle')
      }
      
      return ''
    }
  },
  methods: {
    ...analyticsMethods,
    ...helpers,
    getDateConfigByOptions(){
      if(this.groupButtonSelected == 'day'){
        return { month: 'short', day: 'numeric', timeZone: 'UTC'}
      }
      else if(this.groupButtonSelected == 'week'){
        return {month: 'long', day: 'numeric', timeZone: 'UTC'}
      }else{
        return {month: 'long', timeZone: 'UTC'}
      }
    },
    
    selectButtonFilter(day){
      this.groupButtonSelected = day;
      this.loadNewData();
    },
    onValueChanged(){
      this.loadNewData();
    },
    aggregatePreviousWeekly(data) {
      const weeklyData = {};

      data.forEach(item => {
        const date = new Date(item.date);
        date.setDate(date.getDate() + this.periodLength); 
        const startOfWeek = this.getStartOfWeek(date).toISOString().split('T')[0]; 
        const weekYear = startOfWeek;

        if (!weeklyData[weekYear]) {
          weeklyData[weekYear] = {
            date: new Date(weekYear),
            executions: 0,
            success: 0,
            opened: 0,
            views: 0,
            converted: 0,
            revenue: 0,
          };
        }

        weeklyData[weekYear].executions += item.executions;
        weeklyData[weekYear].success += item.success;
        weeklyData[weekYear].opened += item.opened;
        weeklyData[weekYear].views += item.views;
        weeklyData[weekYear].revenue += parseFloat(item.revenue);
        weeklyData[weekYear].converted += item.converted;
      });

      this.weeklyPreviousTendings = Object.values(weeklyData);
    },
    aggregateWeekly(data) {
       const weeklyData = {};

      data.forEach(item => {
        const date = new Date(item.date);
        const startOfWeek = this.getStartOfWeek(date).toISOString().split('T')[0]; 
        const weekYear = startOfWeek;

        if (!weeklyData[weekYear]) {
          weeklyData[weekYear] = {
            date: new Date(weekYear),
            executions: 0,
            success: 0,
            opened: 0,
            views: 0,
            converted: 0,
            revenue: 0,
          };
        }

          weeklyData[weekYear].executions += item.executions;
          weeklyData[weekYear].success += item.success;
          weeklyData[weekYear].opened += item.opened;
          weeklyData[weekYear].views += item.views;
          weeklyData[weekYear].revenue += parseFloat(item.revenue);
          weeklyData[weekYear].converted += item.converted;
        });

        this.weeklyTendings = Object.values(weeklyData);
      },
    getWeekNumber(date) {
      const startOfYear = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
      const pastDaysOfYear = (date - startOfYear) / 86400000;
      return Math.ceil((pastDaysOfYear + startOfYear.getUTCDay() + 1) / 7);
    },
    getStartOfWeek(date) {
      const startOfWeek = new Date(date);
      const dayOfWeek = startOfWeek.getUTCDay();
      const diff = startOfWeek.getUTCDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); 
      startOfWeek.setUTCDate(diff);
      return startOfWeek;
    },
    aggregateMonthly(data) {
        const monthlyData = {};

        data.forEach(item => {
          // Obtener el mes y el año del item
          const date = new Date(item.date);
          const month = date.getUTCMonth() + 1; // Meses van de 0 a 11
          const year = date.getUTCFullYear();
          const monthYear = `${year}-${month < 10 ? '0' + month : month}`;

          // Inicializar el objeto para el mes si no existe
          if (!monthlyData[monthYear]) {
            monthlyData[monthYear] = {
              date: monthYear,
              executions: 0,
              success: 0,
              opened: 0,
              views: 0,
              converted: 0,
              revenue: 0,
            };
          }

          monthlyData[monthYear].executions += item.executions;
          monthlyData[monthYear].success += item.success;
          monthlyData[monthYear].opened += item.opened;
          monthlyData[monthYear].views += item.views;
          monthlyData[monthYear].converted += item.converted;
          monthlyData[monthYear].revenue += parseFloat(item.revenue);
          
        });

        this.monthlyTendings = Object.values(monthlyData);
      },
      
      aggregatePreviousMonthly(data) {
      const monthlyData = {};

      data.forEach(item => {
        const date = new Date(item.date);
        date.setDate(date.getDate() + this.periodLength); 
        const month = date.getUTCMonth() + 1; 
        const year = date.getUTCFullYear();
        const monthYear = `${year}-${month < 10 ? '0' + month : month}`;

        if (!monthlyData[monthYear]) {
          monthlyData[monthYear] = {
            date: monthYear,
              executions: 0,
              success: 0,
              opened: 0,
              views: 0,
              converted: 0,
              revenue: 0,
          };
        }

        monthlyData[monthYear].executions += item.executions;
        monthlyData[monthYear].success += item.success;
        monthlyData[monthYear].opened += item.opened;
        monthlyData[monthYear].views += item.views;
        monthlyData[monthYear].converted += item.converted;
        monthlyData[monthYear].revenue += parseFloat(item.revenue);
      });

      this.monthlyPreviousTendings = Object.values(monthlyData);
    },
    loadNewData(){
        const loader = this.$loading.show();
        let trends = [];
        let oldTrends = [];
        let length = 0;

        if(this.groupButtonSelected == 'day'){
          trends = this.trendings;
          oldTrends = this.oldTrendings;
          length = this.periodLength;
        }else if(this.groupButtonSelected == 'week'){
          trends = this.weeklyTendings;
        }else if(this.groupButtonSelected == 'month'){
          trends = this.monthlyTendings;
        }
        
        if(!this.compare){
            this.series= [{
              name: this.auxValue.label,
              data:[]
            }]
          }else{
            this.series= [
              {
                name: this.auxValue.label,
                data: [],
              },
              {
                name: `${this.$t('common.previous_period')} (${this.periodLength} ${this.periodLength>1 ? this.$t('common.days_badge') : this.$t('common.day_badge')})`,
                data: [],
              },
            ]
          }

        switch(this.auxValue.id){
          case 'sent':
            if(this.trendings){
              this.series[0].data = Array.from(trends, x => [ new Date(x.date).getTime() , x.executions || 0]);
              this.series[0].data.sort((a, b) => a[0] - b[0]);

              if(this.compare && this.oldTrendings){
                this.series[1].data = Array.from(oldTrends, x => {
                  let date = new Date(x.date);
                  date.setDate(date.getDate() + length); 
                  return [date.getTime(), x.executions || 0];
                });
                this.series[1].data.sort((a, b) => a[0] - b[0]);
              }
            }
              
            this.$refs.TrendsChart?.updateSeries(this.series,true);
            loader.hide();
            
            break;
          case 'success':
              if(this.trendings){
                this.series[0].data = Array.from(trends, x => [ new Date(x.date).getTime() , x.success || 0]);
                this.series[0].data.sort((a, b) => a[0] - b[0]);

                if(this.compare && this.oldTrendings){
                  this.series[1].data = Array.from(oldTrends, x => {
                    let date = new Date(x.date);
                    date.setDate(date.getDate() + length); 
                    return [date.getTime(), x.success || 0];
                  });
                  this.series[1].data.sort((a, b) => a[0] - b[0]);
                }
              }
              this.$refs.TrendsChart?.updateSeries(this.series,true);
              loader.hide();
            break;
          case 'revenue': 
              if(this.trendings){
                this.series[0].data = Array.from(trends, x => [ new Date(x.date).getTime() , x.revenue || 0]);
                this.series[0].data.sort((a, b) => a[0] - b[0]);

                if(this.compare && this.oldTrendings){
                  this.series[1].data = Array.from(oldTrends, x => {
                    let date = new Date(x.date);
                    date.setDate(date.getDate() + length); 
                    return [date.getTime(), x.revenue || 0];
                  });
                  this.series[1].data.sort((a, b) => a[0] - b[0]);
                }
              }

              this.$refs.TrendsChart?.updateSeries(this.series,true);
              loader.hide();
            break;
          case 'opened': 
            if(this.trendings){
                this.series[0].data = Array.from(trends, x => [ new Date(x.date).getTime() , x.opened || 0]);
                this.series[0].data.sort((a, b) => a[0] - b[0]);

                if(this.compare && this.oldTrendings){
                  this.series[1].data = Array.from(oldTrends, x => {
                    let date = new Date(x.date);
                    date.setDate(date.getDate() + length); 
                    return [date.getTime(), x.opened || 0];
                  });
                  this.series[1].data.sort((a, b) => a[0] - b[0]);
                }
              }
              this.$refs.TrendsChart?.updateSeries(this.series,true);
              loader.hide();
            break;
          case 'views': 
              if(this.trendings){
                this.series[0].data = Array.from(trends, x => [ new Date(x.date).getTime() , x.views || 0]);
                this.series[0].data.sort((a, b) => a[0] - b[0]);

                if(this.compare && this.oldTrendings){
                  this.series[1].data = Array.from(oldTrends, x => {
                    let date = new Date(x.date);
                    date.setDate(date.getDate() + length); 
                    return [date.getTime(), x.views || 0];
                  });
                  this.series[1].data.sort((a, b) => a[0] - b[0]);
                }
              }

              this.$refs.TrendsChart?.updateSeries(this.series,true);
                
              loader.hide();
            break;
          case 'converted': 
            if(this.trendings){
              this.series[0].data = Array.from(trends, x => [ new Date(x.date).getTime() , x.converted || 0]);
              this.series[0].data.sort((a, b) => a[0] - b[0]);

              if(this.compare && this.oldTrendings){
                  this.series[1].data = Array.from(oldTrends, x => {
                    let date = new Date(x.date);
                    date.setDate(date.getDate() + length); 
                    return [date.getTime(), x.converted || 0];
                  });
                  this.series[1].data.sort((a, b) => a[0] - b[0]);
                }
            }
            this.$refs.TrendsChart?.updateSeries(this.series,true);
            loader.hide();
          break;
          }
      },
  },
  watch:{
    trendings: {
        immediate: true,
        handler(newVal){
          if(newVal){
            this.weeklyTendings = [];
            this.monthlyTendings = [];
            this.aggregateMonthly(newVal);
            this.aggregateWeekly(newVal);
            this.loadNewData();
          }
        }
    },
    oldTrendings: {
        immediate: true,
        handler(newVal){
          if(newVal){
            this.weeklyPreviousTendings = [];
            this.monthlyPreviousTendings = [];
            this.aggregatePreviousMonthly(newVal);
            this.aggregatePreviousWeekly(newVal);
            this.loadNewData();
          }
        }
    }
  }
};
</script>

<template>
  <div class="card-body">
    <div class="d-flex flex-wrap">
      <div class="d-flex flex-column">
        <h5 class="card-title me-2">Trendings</h5>
        <p class="small mb-0">{{getSubtitleText}}</p>
      </div>
      
      <div class="ms-auto d-inline-flex">
        <multiselect 
            :options="values"
            :placeholder="''"
            track-by="id" 
            label="label"
            :show-labels="false"
            :allow-empty="false"
            :multiple="false"
            v-model="auxValue"
            @input="onValueChanged()"
            style="width: 300px;"
            class="me-2"
            />
        <div class="btn-group btn-group-sm pl-2 w-70" role="group" style="width: 70%">
          <button type="button" :class="groupButtonSelected =='day' ? 'btn btn-primary' :'btn btn-outline-primary'" @click="selectButtonFilter('day')">{{$t('common.day')}}</button>
          <button type="button" :class="groupButtonSelected =='week' ? 'btn btn-primary' :'btn btn-outline-primary'" @click="selectButtonFilter('week')">{{$t('common.week')}}</button>
          <button type="button" :class="groupButtonSelected =='month' ? 'btn btn-primary' :'btn btn-outline-primary'" @click="selectButtonFilter('month')">{{$t('common.month')}}</button>
        </div>
      </div>
    </div>

    <hr class="mb-2" />

    <apexchart
      ref="TrendsChart"
      class="apex-charts"
      dir="ltr"
      height="350"
      :options="chartoptions"
      :series="series.length > 0 ? series : []"
    ></apexchart>
  </div>
</template>
